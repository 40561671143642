/* Tailwind directives */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inknut+Antiqua:wght@300&display=swap");

@layer base {
	body {
		@apply font-FamilyPrimary;
	}

	h1 {
		@apply font-extrabold;
		@apply text-xl;
		@apply md:text-2xl;
		@apply lg:text-3xl;
	}

	h2 {
		@apply text-lg;
		@apply md:text-xl;
		@apply lg:text-3xl;
	}

	h3 {
		@apply font-semibold;
		@apply text-sm;
		@apply md:text-lg;
		@apply lg:text-xl;
	}

  h5 {
		@apply font-thin;
		@apply text-base;
		@apply lg:text-xl;
	}

  p, a {
    @apply text-base;
  }
  

}
